import React,{useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import { useParams,useLocation } from 'react-router';
import queryString from 'query-string'
import { Link } from 'react-router-dom';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    text-align:center;
    padding:20px;
`;

const SearchData = () => {
    const params = useParams();
    const location = useLocation();
    const [alldata, setgetData] = useState()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // console.log(queryString.parse(location.search));
        getData();    
    }, [])

    let getData = async() => {
        // return
        let data = queryString.parse(location.search);
        // let brandid = data.brandid;
        let zipcode = data.zipcode;
        const res = await fetch('https://www.hiforme.com/hiformeapi/fetchSearchData', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",            
            },
            body: JSON.stringify({
                zipcode
            })
        });

        let fdata = await res.json();
        if(fdata){  
            // console.log(fdata.data);      
            setgetData(fdata.data);
        }
    }
    let url = '';
    let contact ="";
    // if(alldata && alldata.length>0){
    //     console.log(alldata);
    //     contact = `tel:${alldata.brand_contactno}`;
    // }

    let city = params.city.slice(0,1).toUpperCase()+ params.city.slice(1, params.city.length)
    let newcity = city.replace('-',' ')

    return (
        <>
            <br/><br/><br/><br/>
            <hr/>
            <div className="container">      
            <Helmet>
            <meta name="description" content="hsforme.com" />

                {/* <meta charSet="utf-8" /> */}
                {/* <title>Monitored Alarm System | Monitored Alarm Company | HSforme</title>
                <meta name="description" content= "HSforme is the best Home alarm monitoringcompany which provides Monitored Alarm System for Home and Business, with immediate audio response monitoring."/>
                <meta charset="UTF-8" />
                <meta name="author" content="HSForMe" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="google-site-verification" content="NQ15ISZFm2ehpTMbI9LHviaYsXCulgkZqtYJ8cE-2go" />
                <meta name="google-site-verification" content="MQtA8VuxqxU6vxIYsUEkyMDCU4UK4jqZNNqvP-J6nQs" />
                <link rel="canonical" href="http://www.hsforme.com"/> 
                <link rel="icon" href="https://hsforme.com/assets/images/HSFORME.jpg" sizes="16x16 32x32" type="image/jpg"/>                 */}
            </Helmet>       

       
            

            <section className="search-result">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                                        <div className="result-header">
                                            Insurance Service Provider {newcity}, {params.params.toUpperCase()}
                                        </div>
                                        
                                        { alldata && alldata.length > 0 ?
                                            alldata.map((item,index)=>{
                                            let no = item.brand_contactno.replace(/(\,)/g,'')
                                            let url = `/${item.BrandUrl}/${params.city}/${params.params}`;    
                                            contact = `tel:${no}`;
                                            return(    
                                            <div className="search-block">
                                                
                                                <div className="search-content">
                                                    <div className="brand-content">
                                                        <div className="row">
                                                            <div className="col-md-12 text-md-left">
                                                                <span className="p-2 1brand-count">{index+1}</span>
                                                                <span className="brand-name1 text-black">{item.BrandName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row text-sm-center text-sm-left">
                                                            <div className="col-md-3" style={{borderRight: '0.1rem solid #ddd'}}>
                                                            <span className="brand-info-img"><img src={'../../images/brand/'+item.brand_img} alt="" style={{width:'200px!important'}} className="img-fluid"/></span>
                                                            {/* <span className="brand-info-img"><img src={'../../images/brand/commingsoon.jpg'} alt="" className="img-fluid w-50"/></span> */}
                                                            </div>
                                                            <div className="col-md-2 text-center pt-5" style={{borderRight: '0.1rem solid #ddd'}}>
                                                                <p className="text-center mb-1"><b>&nbsp;100%</b></p>
                                                                <p className="text-center"><b>Secure</b></p>
                                                            </div>
                                                            <div className="col-md-3 text-sm-center text-md-left" style={{fontSize: '11px'}} style={{borderRight: '0.1rem solid #ddd'}}>
                                                                {/* <div dangerouslySetInnerHTML={ { __html: item.brand_content}} /> */}
                                                                <ul className="list-unstyled">
                                                                    <li>Rx Drugs</li>
                                                                    <li>Hospital & Medical</li>
                                                                    <li>Dental</li>
                                                                    <li>Vision</li>
                                                                    <li>Hearing</li>
                                                                    <li>Fitness Benefits</li>
                                                                    <li>Over-the-Counter Benefits</li>
                                                                    <li>Telehealth Services</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-3 text-center pt-3">
                                                                <span>Set up new service: </span> <br/>
                                                                {alldata && alldata.length>0 &&
                                                                    // <a href={contact}>{item.brand_contactno}</a>
                                                                    // <a style={{'background': '#2331f9','borderRadius': '5px'}} className="btn btn-block" href="#"><span className="fa fa-phone"></span> (XXX) XXX-XXXX</a>
                                                                    <a className="btn btn-primary btn-block" href="#">(XXX) XXX-XXXX</a>
                                                                } 
                                                                <Link style={{'background': '#002467','borderRadius': '5px'}} 
                                                                className="btn btn-primary btn-block" to={url}>View More</Link>    
                                                               
                                                                {/* <a className="btn btn-primary btn-block" href="#">View More</a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                            </div>
                                            )
                                            })
                                            :<SyncLoader loading={loading} css={override} size={10} />
                                        }
                                </div>
                            </div>
                        </div>
                    </section>


            </div>
        </>
    )
}

export default SearchData
