import * as All from './ExportPages';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import logo from './logo.svg';
import './App.css';

const Routing = () => {
  return(
    <Switch>
    <Route exact path="/">
      <All.Index />
    </Route>
{/*    
    <Route exact path="/monitored-systems">
      <All.MonitoredSystems />
    </Route>
   
    <Route exact path="/professionally-installed-systems">
      <All.ProfessionallyInstalledSystems />
    </Route>
   
    <Route exact path="/non-monitored-systems">
      <All.NonMonitoredSystems />
    </Route>
    
    <Route exact path="/self-installed-systems">
      <All.SelfInstalledSystems />
    </Route>
    
    <Route exact path="/security-systems-for-renters">
      <All.SecuritySystemsForRenters />
    </Route>
    
    <Route exact path="/adt-home-security-services">
      <All.States />
    </Route>
    
    <Route exact path="/adt-home-security-services/:id">
      <All.City />
    </Route> */}

    <Route path="/insuranceprovider/:city/:params">
      <All.SearchData />
    </Route>
   
    <Route path="/:brandname/:name/:short">
      <All.Location />
    </Route>
{/*     
    <Route path="/location">
      <All.Aetna />
    </Route> */}

    <Route exact path="/insuranceprovider">
      <All.SearchError />
    </Route>
    
    <Route exact path="/about-us">
      <All.AboutUs />
    </Route>

    

    <Route>
      {/* <PageNotFound /> */}
    </Route>

    </Switch>
  )
}

function App() {
  return (
    <div className="App">
      <Router>
      <All.Header/>
      <Routing/>
      <All.Footer/>
      </Router>
    </div>
  );
}

export default App;
