import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    text-align:center;
    padding:20px;
`;

const Location = () => {
    const params = useParams();
    const [state, setstate] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      getcitydata()
    }, [])

    const getcitydata = async () => {
      // console.log(searchdata);
      setLoading(true);
      let citydata = {
        brand : params.brandname,
        city : params.name.replace('-',' '),
        state : params.short,
      }
      // console.log(citydata);
      const res = await fetch('https://www.hiforme.com/hiformeapi/getCityData', {
          method: "POST",
          headers: {
              "Content-Type": "application/json",            
          },
          body: JSON.stringify({
            citydata
          })
      });

      const data = await res.json();
      if(data.status == 0 || !data){
        // console.log(data); 
        setstate('');       
      }else{    
        let city = params.name.replace('-',' ');      
        let data1 = data.content.replaceAll('#CITY#', city.charAt(0).toUpperCase() + city.slice(1))
        let newdata = data1.replaceAll('#STATE#', params.short.toUpperCase())
        setstate(newdata)  
      }
      setLoading(false);
  }

    let city = params.name.charAt(0).toUpperCase() + params.name.slice(1);
    let short = params.short.toUpperCase();
    let metatitle = "";
    let metadescription = "";
    let metakeyword = "";
    if(params.brandname == 'aetna'){
      metatitle = `Aetna in ${city}, ${short} | Aetna Health Insurance Plans | Hiforme`;
      metadescription = `Aetna is a leading health insurance company in ${city} that offers health insurance plans for individuals, families and small businesses near you. Find out more about Aetna's plans today.`;
      metakeyword = `Aetna in ${city}, Aetna Health Insurance Plans ${city}, Aetna ${city} for Individuals, Aetna ${city} for Family, Aetna plans In ${city}.`;
    }
    
    if(params.brandname == 'blue-cross-blue-shield'){
      metatitle = `Blue Cross Blue Shield Health Insurance Plans ${city}, ${short} - Hiforme`;
      metadescription = `Blue Cross Blue Shield is the one of the largest health insurance providers in the ${city}. It offers a wide range of health plans and services, including Medicare Advantage, Medicaid, and prescription drug plans.`;
      metakeyword = `Blue Cross Blue Shield ${city}, Blue Cross Blue Shield Plans ${city}, BCBS Health Insurance Plans ${city}, Blue Cross Blue Shield of ${city} `;
    }
    
    if(params.brandname == 'cigna'){
      metatitle = `Cigna ${city}, ${short} | Cigna Health Insurance Plans 2022 | Hiforme`;
      metadescription = `Cigna health insurance plans 2022 in ${city}, ${short}. Hiforme offer a variety of prescription drug coverage and behavioral health benefits. Get a free quote today!.`;
      metakeyword = `Cigna in ${city}, Cigna Health Insurance Plans ${city}, Cigna Drug Coverage ${city}, Cigna for Individuals, Cigna for Family, Cigna Plans in ${city}`;
    }
    
    if(params.brandname == 'coventry-health-care'){
      metatitle = `Coventry Health Care in ${city}, ${short} - Hiforme`;
      metadescription = `Coventry Health Care is focused on providing quality healthcare for ${city}, ${short}. With a primary focus on preventive care, our ${city} office offers a variety of services to help you enjoy a healthy life.`;
      metakeyword = `Coventry Health Care in ${city}, Coventry Health Care Plans in ${city}, Coventry Health Care Insurance Providers, Coventry Health Care Near ${city}`;
    }
    
    if(params.brandname == 'coventry-health-care'){
      metatitle = `Coventry Health Care in ${city}, ${short} - Hiforme`;
      metadescription = `Coventry Health Care is focused on providing quality healthcare for ${city}, ${short}. With a primary focus on preventive care, our ${city} office offers a variety of services to help you enjoy a healthy life.`;
      metakeyword = `Coventry Health Care in ${city}, Coventry Health Care Plans in ${city}, Coventry Health Care Insurance Providers, Coventry Health Care Near ${city}`;
    }

    
    return (
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metatitle}</title>
            <link rel="canonical" href="https://wwww.hiforme.com/" />
            <meta name="description" content={metadescription}></meta>
            <meta name="keywords" content={metakeyword}/>            
        </Helmet>   
      <br/><br/>
      
      {state ?
      <div dangerouslySetInnerHTML={{__html: state}} />
      : 
      <>   
      <br />
      <br />
      <hr />
      <br />
      <SyncLoader loading={loading} css={override} size={10} />
      {/* <h3>No Brand data available</h3>
      <Link to="/" className='btn btn-primary'>Back to Home</Link> */}
      <br />
      <br />
      </>
      }


      {/* <div class="container-fluid text-center text-white call-now">
          <div class="row">
              <div class="col-md-12">
                  <h4 class="mb-0" style={{fontSize: '2.0rem'}}>How do you feel about your health today? <a
                          href="tel:+1XXXXXXXXXX" class="btn btn-callnow btn-lg text-white ml-3"><i
                              className="fa fa-phone"></i> (XXX) XXX-XXXX</a></h4>
              </div>
          </div>
      </div> */}
  </div>
    )
  }

export default Location
