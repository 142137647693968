import React from 'react'
import { Route, Link } from 'react-router-dom'
const Header = () => {
    return (
        <>
            <Route>
             {/* <div className="row top-bar">
                    <div className="col-md-12 text-right">
                        <Link  to={"tel:+18774481379"} className="header-call">Call Now: <i className="fa fa-phone" aria-hidden="true"></i> (877) 448-1379</Link>

                </div>
             </div> */}
            <nav className="navbar fixed-top navbar-toggleable-md navbar-light bg-faded header-one float-right">
                <div className="container ">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <h4><Link  className="nav-link" to={"/"}><img className="logo1" src="../../images/logo.webp" /></Link></h4>

                    <div className="collapse navbar-collapse justify-content-right" id="navbarNav">
                        <ul className="navbar-nav menu ml-auto">
                            <li className="nav-item active">
                                <span><Link  className="nav-link" to={"/"}>Home</Link></span>
                            </li>
                            <li className="nav-item">
                                <span><Link  className="nav-link" to={"/about-us"}>About us</Link></span>
                            </li>
                             {/* <li className="nav-item dropdown">
                                <Link  className="nav-link dropdown-toggle" to={"#"} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-animations="fadeInDown">
                                Health Insurance
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link  className="dropdown-item" to={"monitored-systems"}>Monitored Systems</Link>
                                    <Link  className="dropdown-item" to={"professionally-installed-systems"}>Professionally Installed Systems</Link>
                                    <Link  className="dropdown-item" to={"non-monitored-systems"}>Non-Monitored Systems</Link>
                                    <Link  className="dropdown-item" to={"self-installed-systems"}>Self-Installed Systems</Link>
                                    <Link  className="dropdown-item" to={"security-systems-for-renters"}>Security Systems for Renters</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <span><Link  className="nav-link" to={"/resources"}>Resources</Link></span>
                            </li> */}
                            {/* <li className="nav-item dropdown">
                                <Link  className="nav-link dropdown-toggle" to={"#"} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-animations="fadeInDown">
                                    System Type
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link  className="dropdown-item" to={"monitored-systems"}>Monitored Systems</Link>
                                    <Link  className="dropdown-item" to={"professionally-installed-systems"}>Professionally Installed Systems</Link>
                                    <Link  className="dropdown-item" to={"non-monitored-systems"}>Non-Monitored Systems</Link>
                                    <Link  className="dropdown-item" to={"self-installed-systems"}>Self-Installed Systems</Link>
                                    <Link  className="dropdown-item" to={"security-systems-for-renters"}>Security Systems for Renters</Link>
                                </div>
                            </li>


                            <li className="nav-item dropdown">
                                <Link  className="nav-link dropdown-toggle" to={"#"} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-animations="fadeInDown">
                                    Brands
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link  className="dropdown-item" to={"adt-home-security-services"}>ADT Security Services</Link>                                   

                                </div>
                            </li> */}
                            <a href="callto:8888055456" className="btn btn-flashy">Call us: (888) 805-5456</a>                            
                        </ul>
                    </div>
                </div>
            </nav>
        </Route>        
        </>
    )
}

export default Header
