import React, {useState} from "react";
import { Carousel } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useHistory } from "react-router";
import { css } from "@emotion/react";
import {Helmet} from "react-helmet";
import SyncLoader from "react-spinners/SyncLoader";
const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        text-align:center;
        padding:20px;
		`;

const Index = () => {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");    
    
    const [searchdata, setSearchdata] = useState({
        brandid:1,zipcode:''
    });
    let history = useHistory();

    function handleChange(event) {
        setSearchdata({...searchdata, [event.target.name]:event.target.value});
    }

    
    const homesearch = async () => {
        // console.log(searchdata);
        setLoading(false);
        const res = await fetch('https://www.hiforme.com/hiformeapi/getSearchData', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",            
            },
            body: JSON.stringify({
                searchdata
            })
        });

        const data = await res.json();
        if(data.status == 0 || !data){
            history.push('/insuranceprovider');
        }else{           
            let url = data.url;
            // console.log(url); return
            history.push(url);
        }
        setLoading(false);
    }

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cheap Health Insurance | Health Insurance Companies - Hiforme</title>
                <link rel="canonical" href="https://wwww.hiforme.com/" />
                <meta name="description" content="Cheap health insurance companies that offer affordable coverage to you and your family. find the best online health insurance, quotes, and plans. Get your affordable health insurance today!"></meta>
                <meta name="keywords" content="Cheap Health Insurance, Health Insurance Companies, Health Insurance for Family, Health Insurance for Business, Online Health Insurance, Health Insurance Coverage, Health Insurance Plans"/>            
            </Helmet>   

            <br/>
            <br/>
            <br/>
            <br/>
          


            {/* <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="../images/BG2.jpg"
                        alt="First slide"
                    />                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="../images/BG3.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="../images/BG1.jpg"
                        alt="Third slide"
                    />                  
                </Carousel.Item>
            </Carousel> */}
            {/* <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <img className="d-block img-fluid imgslide" src="<?= base_url();?>assets/images/BG2.jpg" alt="Home Alarm Security System"/>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block img-fluid imgslide" src="<?= base_url();?>assets/images/BG3.jpg" alt="Home Security Services"/>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block img-fluid imgslide" src="<?= base_url();?>assets/images/BG1.jpg" alt="Monitored System"/>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>

            </div> */}


            <div className="container-fluid search" style={{background:"url('../../images/Main-Banner.webp')"}}>
                <div className="row">
                    <div className="col-md-12">
                        <h1 style={{fontSize: '43px',fontWeight: '700'}} className="searchtitle">Cheap health Insurance for a healthy life</h1>
                        <h2 style={{fontSize: '30px',marginBbottom: '1.5rem',fontWeight: '500'}} className="searchtitle">Get your health covered today</h2>
                    </div>
                </div>
                <br/>
                <h4 className="text-white" style={{fontSize: '21px'}}>
                            Provide your ZIP and see Medicare plans in your area. Fast. Easy. 
                        </h4><br/>
                    <div className="row justify-content-center">
                        
                        <form className="form-inline">
                        {/* <select name="brandid" id="brand-list" className="form-control">
                        <option value="0">All Brands</option>
                        <option value="1">ADT Security Services</option>
                
                        </select> */} 
                        <input type ="text" name="zipcode" required minlength="5" maxlength="5" className="form-control mb-2 mr-sm-2 mb-sm-0 zip-search" id="inlineFormInput" placeholder="Zipcode"  onChange={handleChange} value={searchdata.zipcode}/>

                        { 
                        loading ?
                        <input type ="button" onClick={homesearch} className="btn btn-primary search-btn" value="Find Medicare Plans"/>
                        : <SyncLoader css={override} size={10} />
                        }
        
                        {/* <div className="sweet-loading">
                        <SyncLoader css={override} size={12} color={"#30C880"} speedMultiplier={1.0} />
                        </div> */}

                        </form>



                        </div>
                    </div>
                    <div className="">
                       <img src="../../images/We-work-with-the-top-carriers.webp" className="img-fluid"/>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row justify-content-center">
                                <form className="form-inline">
                                    <div className="col-md-12">

                                        <h3>Cheap Health Insurance That Fits Your Needs</h3>
                                        <img src="../images/lines.png" alt="lines" className="justify-content-center"/> <br/>
                                        <Tabs>
                                            <TabList>
                                            <Tab>Individual and Family</Tab>
                                            <Tab>Short-term</Tab>
                                            <Tab>Medicaid</Tab>
                                            <Tab>Medicare</Tab>
                                            <Tab>Dental</Tab>
                                            </TabList>

                                            <TabPanel className="text-left ">
                                            
                                            
                                            <div className="row">
                                                <div className="col-md-9 mt-4">
                                                    <h4>Health Insurance for Individuals and Family</h4>
                                                    <p className="text-justify">Individual and family health insurance plans can help you stay on top of preventative healthcare
                                                    services as well as cover emergency expenses. Health Insurance saves money with doctor's visits,
                                                    prescriptions drugs, or any other medical needs that may arise in your life!</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                <img style={{width:"155px"}} src="../images/Health-Insurance-for-Individuals-and-Family.webp" alt="Security System" className="img-fluid shadow" />
                                                </div>
                                            </div>
                                           
                                            </TabPanel>
                                            
                                            <TabPanel className="text-left">
                                            <div className="row">
                                                <div className="col-md-9 mt-4">
                                                <h4>Short-Term Health Insurance</h4>
                                                <p className="text-justify">Short-term plans are designed to provide temporary health coverage with affordability. They're easy
                                                    and affordable,  so if you experience short gaps where your job loss or other event  has made it
                                                    difficult for long term insurance options then this is the perfect solution!</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                <img style={{width:"155px"}} src="../images/Short-Term-Health-Insurance.webp" alt="Security System" className="img-fluid shadow" />
                                                </div>
                                            </div>
                                          
                                            </TabPanel>
                                            
                                            <TabPanel className="text-left">
                                            <div className="row">
                                                <div className="col-md-9 mt-4">
                                                <h4>Check your eligibility for Medicaid</h4>
                                                <p className="text-justify">In states that expanded Medicaid eligibility, more than ten million low-income Americans gained
                                                    access to health insurance. See if your state is one of them! Then use our Federal Poverty Level
                                                    Calculator and see how you might qualify for coverage under the ACA's Medicaid expansion.</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                <img style={{width:"155px"}} src="../images/Check-your-eligibility-for-Medicaid.webp" alt="Security System" className="img-fluid shadow" />
                                                </div>
                                            </div>
                                            </TabPanel>
                                        
                                            <TabPanel className="text-left">
                                           
                                            <div className="row">
                                                <div className="col-md-9 mt-4">
                                                    <h4>Medicare</h4>
                                                    <p className="text-justify">Medicare is a great  option for people who are looking to protect  themselves from high medical
                                                        expenses. It consists of two parts, the Hospital Insurance Plan (Part A) which covers costs in case
                                                        you  need  emergency  treatment  and  long-term  care  facilities  like  nursing  homes;  as  well  as
                                                        Prescription drug coverage with no monthly premiums!  There's  also an Enhanced Choice plan
                                                        available where we can find all sorts of helpful information about Medicare at our resource center:
                                                        go check it out today!.</p>

                                                </div>
                                                <div className="col-md-3 text-center">
                                                <img style={{width:"155px"}} src="../images/Medicare.webp" alt="Security System" className="img-fluid shadow" />
                                                </div>
                                            </div>
                                            </TabPanel>
                                            
                                            <TabPanel className="text-left">
                                            <div className="row">
                                                <div className="col-md-9 mt-4">
                                                    <h4>Flexible dental benefits. Fast approval.</h4>
                                                    <p className="text-justify">Dental insurance can protect you from the high costs of dental procedures. Compare plan options
                                                        and see what premiums or deductibles fit your budget best!</p>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                <img style={{width:"155px"}} src="../images/Flexible-dental-benefits.-Fast-approval.webp" alt="Security System" className="img-fluid shadow" />
                                                </div>
                                            </div>
                                            </TabPanel>
                                            
                                        </Tabs>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>

                    <h2>Speak with a customer service professional by phone. </h2>
                    <br/>
                    <a href="callto:8888055456" className="btn btn-flashy">Call us: (888) 805-5456</a>
                    <br/>
                    <br/>

                    {/* <div className="container-fluid system">
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-3">

                                    <div className="text-center">
                                        <i className="fa fa-eye fa-4x"></i>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="text-center">
                                        <strong><a href="<?php echo base_url()?>monitored-systems">Monitored Systems</a></strong>
                                        <p>A Monitored System is the best option you can possibly have in regards to protecting your valuables and loved ones.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <i className="fa fa-plus-square fa-4x"></i>

                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="text-center">
                                        <strong><a href="<?php echo base_url()?>professionally-installed-systems">Professionally Installed Systems</a></strong>
                                        <p>A Professionally Installed Systems is the best option you can possibly have in regards to protecting your valuables and loved ones.</p>
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="text-center">
                                        <i className="fa fa-eye-slash fa-4x"></i>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="text-center">
                                        <strong><a href="<?php echo base_url()?>non-monitored-systems">Non-Monitored Systems</a></strong>
                                        <p>Non-Monitored systems are still great even though they are a bit outdated.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <i className="fa fa-home fa-4x"></i>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="text-center">
                                        <strong><a href="<?php echo base_url()?>self-installed-systems"> Self Installed Systems</a></strong>

                                        <p>.com only recommends self installation home security systems for the above average DIY consumer.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}


                    <p>&nbsp;</p>
                    <div className="container home-security">
                        <div className="cotainer-fluid ">
                            <div className="row">

                                <div className="col-md-6">
                                    <img src="../images/Your-Wellness-Matters.webp" alt="Security System" className="img-fluid shadow" />
                                </div>


                                <div className="col-md-6 margin">
                                    <h3>Your Wellness Matters</h3>
                                    <b>Healthy choices lead to healthy results! </b>
                                    <p className="justify-content-justify">
                                        That's  why  TY Health  Insurance  Brokerage  is  actively
                                        involved in the fight against obesity. We work hard with our
                                        clients and their employees so you can feel confident about
                                        what  matters  most  -  keeping  your  people  on  track  for
                                        success, both physically & financially.
                                    </p>
                                    <h4 className="text-center">Speak with insurance agent today<br/><br/>
                                    <a href="callto:8888055456" className="btn btn-flashy">Call us: (888) 805-5456</a>
                                    </h4>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="container home-security mt-5 pt-5">
                        <div className="cotainer-fluid ">
                            <div className="row">
                                <div className="col-md-6 margin1">
                                    <h3>Looking For Best Health Insurance Plans?</h3>
                                    <p>Your health is important. Don't risk it!</p>
                                  <p>We offer a wide range of health insurance plans from some
                                    of the best companies in New York. From NYC to Buffalo,
                                    explore our selection and find what works for you!</p>

                                <p>You know how much we care about your well-being here at Best Insurance Plan - so why not let us help you by offering the best possible coverage and protection for all those medical needs? Let's talk through what kind of plan will be right for YOU today, together with our top notch customer service team who are always ready to answer any questions or queries that may pop up along this process.</p>
                                
                                <h4 className="text-center">Call for more information today.<br/>
                                <a href="callto:8888055456" className="btn btn-flashy mt-2">Call us: (888) 805-5456</a>                                    
                                </h4>
                                </div>
                                <div className="col-md-6">
                                <img src="../images/Looking-to-Purchase-New-York-Health-Insurance.webp" alt="Success" className="img-fluid shadow" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>


        </>
    );
}

export default Index