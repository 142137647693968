import React from 'react'
import { Link } from 'react-router-dom'

const SearchError = () => {
    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <section className="search-result">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="result-block">
                                <div className="search-error-block">
                                <p className="search-error-head">Opps!!</p>
                                <p className="search-error">There is no result available for zip you entered.<br/> Please go back and try another.</p>
                                <p className="back-to-home"><Link to="/">Back to Homepage</Link></p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SearchError
