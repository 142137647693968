import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="container-fluid footer-widget">
                <div className="container">

                    <div className="row">
                        <div className="col-md-4 col-sm-4 text-left">
                            <img src="../../images/logo.webp" alt="Footer Logo" className="img-fluid" srcset=""/><br/><br/>
                            <nav className="nav flex-column footer-social">
                            <h6><u><b>Connect: </b></u></h6><br/>
                            <Link to="#" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                            <Link to={"#"} className="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                            <Link to={"#"} className="linkedin"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                            <Link to={"#"} className="pinterest"><i className="fa fa-pinterest" aria-hidden="true"></i></Link>

                            </nav>
                        </div>

                        <div className="col-md-4 col-sm-4 text-left pl-5">
                            <h4><u><b>Service and Plans</b></u></h4>
                            <nav className="nav flex-column footernav">
                            <Link className="nav-link" to={"/"}>Individual and Family</Link>
                            <Link className="nav-link" to={"/"}>Short-term</Link>
                            <Link className="nav-link" to={"/"}>Medicaid</Link>
                            <Link className="nav-link" to={"/"}>Medicare</Link>
                            <Link className="nav-link" to={"/"}>Dental</Link>                            
                            </nav>
                            
                        </div>

                        <div className="col-md-4 col-sm-4 text-left">
                            <h4><u><b>Company</b></u></h4>
                            <nav className="nav flex-column footernav">
                            <Link className="nav-link" to={"/"}>About us</Link>
                            <Link className="nav-link" to={"/"}>Health News</Link>
                            <Link className="nav-link" to={"/"}>Privacy Policy</Link>
                            <Link className="nav-link" to={"/"}>Terms of Use</Link>                            
                            </nav>
                            <Link to={"tel:+18888055456"} className="btn btn-flashy m-0">Call Us: <i className="fa fa-phone" aria-hidden="true"></i> (888) 805-5456</Link>
                            
                        </div>

                        </div>                          

                        </div>
                        </div>

                        <div className="container-fluid footer">
                            <hr/>
                            <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                <p className="justify-content-center">Copyright © 2020 <Link to={"/"}>HiForMe</Link> All Rights Reserved</p>
                                </div>

                                {/* <div className="col-md-3 text-right"> */}
                                {/* <Link to={"tel:+18888055456"} className="header-call">Call Now: <i className="fa fa-phone" aria-hidden="true"></i> (888) 805-5456</Link> */}
                                {/* <Link to={"#"} className="back-to-top" style={{display: "inline;"}}>
                                <i className="fa fa-arrow-circle-up"></i>
                                </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>

        </>
    )
}

export default Footer
